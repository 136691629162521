import * as React from "react"
import { Link } from "gatsby"
import { RiArrowDropRightLine } from "react-icons/Ri"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = () => (
  <Layout>
    <Seo title="資料請求" />
    <ul className="page_list">
      <li>
        <Link className="page_link" to="/">Home</Link>
      </li>
      <li><RiArrowDropRightLine className="page_arrow"/></li>
      <li>資料請求</li>
    </ul>
    <div className="contact_txtbox">
    <h1 className="contact_title">資料請求</h1>
    <p className="contact_titles">request</p>
    </div>
  </Layout>
)

export default SecondPage

